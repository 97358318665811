var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var InputSupervisorSalaryTimeType = /** @class */ (function (_super) {
    __extends(InputSupervisorSalaryTimeType, _super);
    function InputSupervisorSalaryTimeType(props) {
        var _this = _super.call(this, props) || this;
        _this.onAdd = _this.onAdd.bind(_this);
        _this.onDelete = _this.onDelete.bind(_this);
        return _this;
    }
    /**
     * Add text to list
     */
    InputSupervisorSalaryTimeType.prototype.onAdd = function () {
        var newValue = this.state.value;
        var timeTypes = this.props.timeTypes || [];
        var existing = timeTypes.find(function (e) { return e === newValue; });
        if (!existing) {
            timeTypes.push(newValue);
            this.props.onChange(timeTypes);
        }
        this.setState({ value: "" });
    };
    /**
     * Delete text from list
     *
     * @param text {string} Text in list to remove
     */
    InputSupervisorSalaryTimeType.prototype.onDelete = function (index) {
        var timeTypes = this.props.timeTypes;
        timeTypes.splice(index, 1);
        this.props.onChange(timeTypes);
    };
    /**
     * Get element for show text in list
     *
     * @param text {string} Text to show
     * @returns {JSX.Element}
     */
    InputSupervisorSalaryTimeType.prototype.getEntry = function (index, text) {
        var _this = this;
        return (React.createElement("div", { key: text, className: "quick-text d-flex-flex-row mx-1" },
            React.createElement("div", null,
                text,
                !this.props.disabled && (React.createElement("i", { className: "delete fas fa-times-circle ml-2", onClick: function () { return _this.onDelete(index); } })))));
    };
    InputSupervisorSalaryTimeType.prototype.onSelected = function (value) {
        var _this = this;
        var timeTypes = this.props.timeTypes || [];
        var existing = timeTypes.find(function (e) { return e === _this.state.value; });
        if (!existing) {
            timeTypes.push(this.state.value);
            this.props.onChange(timeTypes);
        }
    };
    InputSupervisorSalaryTimeType.prototype.render = function () {
        var _this = this;
        var timeTypes = this.props.timeTypes;
        timeTypes = timeTypes || [];
        return (React.createElement("div", { className: "input-quick-text form-group d-flex flex-column align-items-start" },
            this.props.label && React.createElement("label", null, this.props.label),
            !this.props.disabled && (React.createElement("div", null,
                React.createElement("div", { className: "d-flex flex-row" },
                    React.createElement("select", { className: "input-select custom-select", disabled: this.props.disabled, onChange: function (e) {
                            _this.setState({ value: e.target.value });
                        } }, this.props.availableTimeTypes.map(function (o) { return (React.createElement("option", { key: o.value, value: o.label }, o.label)); })),
                    React.createElement("button", { className: "btn btn-primary", onClick: this.onAdd }, "Add")))),
            React.createElement("div", { className: "quick-text-list d-flex flex-wrap" }, timeTypes.map(function (type, index) { return _this.getEntry(index, type); }))));
    };
    return InputSupervisorSalaryTimeType;
}(Component));
export default InputSupervisorSalaryTimeType;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Component } from "react";
import ConfigTool from "../../lib/config";
import InputSelectSimpleView from "../inputs/InputSelectSimpleView";
import InputCheckboxGrid from "../inputs/InputCheckboxGrid";
import InputColorSimpleView from "../inputs/InputColorSimpleView";
import InputTextSimpleView from "../inputs/InputTextSimpleView";
import InputCheckboxSimpleView from "../inputs/InputCheckboxSimpleView";
import InputSelectReactSimpleView from "../inputs/InputSelectReactSimpleView";
import InputOvertimeRules from "../inputs/InputOvertimeRules";
import InputTextList from "../inputs/InputTextList";
import InputSalary from "../inputs/InputSalary";
import mapping from "../../constants/mappingsTravelAndTimeTypes";
import PropTypes from "prop-types";
import RouterLink from "../RouterLink";
import reporting from "../../constants/reporting";
import ButtonWithTooltip from "../ButtonWithTooltip";
import Exclude from "../../constants/exclude";
import mappingsTraveAndTimeTypes from "../../constants/mappingsTravelAndTimeTypes";
import Issue from "../Issue";
import { swagger, validate } from "config-validation";
import util from "../../lib/util";
import "./ConfigSimpleView.scss";
import Url from "../../constants/url.jsx";
import InputSupervisorSalaryTimeType from "../inputs/InputSupervisorSalaryType";
var debug = require("debug")("bk-manager:frontend-react:components:views.ConfigSimpleView");
/**
 *
 * Add list values to header
 *
 * @param config {object} Byggkollen config
 * @param header {array<object>} List with header objects
 * @param translation {object} Current translation
 * @returns {object}
 *
 * */
function addValues(config, header, translation) {
    return header.map(function (h, i) {
        if (i === 0) {
            return h;
        }
        var values = util.clone(util.getNestedObject(config, h.primary.key) || []);
        if (h.primary.language == "key") {
            values = util.translateList(values, translation, h.primary.name_type, "key->sv");
        }
        h.values = values;
        return h;
    });
}
/**
 * Return available data types (time and travel types) for the customers
 *
 * @param config {object} customer config
 * @param mapping {object} mapping of data types
 * @param translation {object} translation mapping
 * @param allowance_mapping {array<object>} mapping allowance type to allowance code
 */
function getDataTypes(config, mapping, translation, allowance_mapping) {
    var data_types = [];
    for (var _i = 0, _a = ["time_type_absence", "time_type_work", "travel_type"]; _i < _a.length; _i++) {
        var _dt = _a[_i];
        var dt = _dt;
        for (var _b = 0, _c = mapping[dt].slice(1); _b < _c.length; _b++) {
            var item = _c[_b];
            var values = util.clone(util.getNestedObject(config, item.primary.key) || []);
            if (item.primary.language == "key") {
                values = util.translateList(values, translation, item.primary.name_type, "key->sv");
            }
            data_types = __spreadArray(__spreadArray([], data_types, true), values, true);
        }
    }
    for (var _d = 0, allowance_mapping_1 = allowance_mapping; _d < allowance_mapping_1.length; _d++) {
        var a = allowance_mapping_1[_d];
        data_types.push(a.allowance_type);
    }
    data_types = __spreadArray(__spreadArray([], data_types, true), reporting.on_call_types, true);
    data_types = util.unique(data_types);
    data_types = util.sort(data_types, null, "desc", true);
    return data_types;
}
/**
 * Render config simplified view
 */
var ConfigSimpleView = /** @class */ (function (_super) {
    __extends(ConfigSimpleView, _super);
    function ConfigSimpleView(props) {
        var _this = _super.call(this, props) || this;
        /**
         * On change time period
         *
         * @param value {string} New time period start
         */
        _this.onTimePeriod = function (_a) {
            var value = _a.value;
            var config_edit = util.clone(_this.props.config_edit);
            util.setNestedObject(config_edit, "web.reportingStartingDate", Number(value));
            util.setNestedObject(config_edit, "mobile.startingSalaryDay", Number(value));
            util.setNestedObject(config_edit, "mobile.endingSalaryDay", Number(value) - 1);
            _this.props.setPageState({ config_edit: config_edit });
        };
        _this.state = {
            docs: swagger().definitions.config.properties,
            clientId: "",
        };
        _this.getHelp = _this.getHelp.bind(_this);
        _this.getOptions = _this.getOptions.bind(_this);
        _this.getRows = _this.getRows.bind(_this);
        _this.getRowsTimeTypeWork = _this.getRowsTimeTypeWork.bind(_this);
        _this.getRowsTimeTypeAbsence = _this.getRowsTimeTypeAbsence.bind(_this);
        _this.getRowsTravelType = _this.getRowsTravelType.bind(_this);
        _this.getTraktamenteValue = _this.getTraktamenteValue.bind(_this);
        _this.onGridChange = _this.onGridChange.bind(_this);
        _this.onGridSortChange = _this.onGridSortChange.bind(_this);
        _this.onGridSync = _this.onGridSync.bind(_this);
        _this.onHazard = _this.onHazard.bind(_this);
        _this.onReportingType = _this.onReportingType.bind(_this);
        _this.onTimePeriod = _this.onTimePeriod.bind(_this);
        _this.onTraktamente = _this.onTraktamente.bind(_this);
        _this.onQuickText = _this.onQuickText.bind(_this);
        _this.onChange = _this.onChange.bind(_this);
        _this.onAllowExtraTime = _this.onAllowExtraTime.bind(_this);
        _this.onReset = _this.onReset.bind(_this);
        return _this;
    }
    ConfigSimpleView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, resJSON;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch("".concat(Url.CLIENT_ID, "?uid=").concat(util.getUid()))];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 2:
                        resJSON = _a.sent();
                        this.setState({
                            clientId: resJSON === null || resJSON === void 0 ? void 0 : resJSON.client_id,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get hazard value depending on mobile fconfig hazard selection
     *
     * @param config {object} Byggkollen config
     * @returns {string}
     */
    ConfigSimpleView.prototype.getHazardValue = function (config) {
        // TODO add config as param
        if (config.mobile.projectMenu.hazard1) {
            return "ajoj";
        }
        else if (config.mobile.projectMenu.hazard2) {
            return "tillbud";
        }
        else {
            return "disabled";
        }
    };
    /**
     * Ge header fpr time type absence
     *
     * @returns {[{label: string, primary: {key: string}},{label: string, maps_to: [{name_type: string, language: string, key: string},{name_type: string, language: string, key: string}], primary: {name_type: string, language: string, key: string}}]}
     */
    ConfigSimpleView.prototype.getHeaderTimeTypeAbsence = function () {
        return mapping.time_type_absence;
    };
    /**
     * Ge header fpr time type work
     *
     * @returns {{label: string, primary: {key: string}}}
     */
    ConfigSimpleView.prototype.getHeaderTimeTypeWork = function () {
        return mapping.time_type_work;
    };
    /**
     * Ge header fpr time typ travel (driving)
     *
     * @returns {[{label: string, primary: {key: string}},{label: string, maps_to: [{name_type: string, language: string, key: string}], primary: {name_type: string, language: string, key: string}},{label: string, maps_to: [{name_type: string, language: string, key: string}], primary: {name_type: string, language: string, key: string}},{label: string, maps_to: [{name_type: string, language: string, key: string},{name_type: string, language: string, key: string}], primary: {name_type: string, language: string, key: string}}]}
     */
    ConfigSimpleView.prototype.getHeaderTravelType = function () {
        return mapping.travel_type;
    };
    /**
     * Get help text from swagger documentation
     *
     * @param key {string} Config key to extract help text for
     * @returns {*}
     */
    ConfigSimpleView.prototype.getHelp = function (key) {
        var help;
        switch (key) {
            default:
                var keys = key.split(".");
                var new_key = keys.join(".properties.");
                help = util.getNestedObject(this.state.docs, new_key).description;
                break;
        }
        return help;
    };
    /**
     * Get valid options from swagger documentation
     *
     * @param key {string} Config key to extract options for
     * @returns {*}
     */
    ConfigSimpleView.prototype.getOptions = function (key) {
        var options = [];
        switch (key) {
            default:
                var keys = key.split(".");
                var new_key = keys.join(".properties.");
                var list = util.getNestedObject(this.state.docs, new_key).enum;
                options = list.map(function (m) {
                    return { value: m, label: m };
                });
                break;
        }
        return options;
    };
    /**
     *  Generic function for getting rows
     *
     * @param allowed_values_sv {array} Allowed swedish translation mapping
     * @param headers {array<object>} Header to use
     * @param config {object} Byggkollen config
     * @returns {*[]}
     */
    ConfigSimpleView.prototype.getRows = function (allowed_values_sv, headers, config) {
        // Ensure
        allowed_values_sv = util.unique(allowed_values_sv, function (k) { return k; });
        allowed_values_sv.sort();
        var rows = [];
        for (var _i = 0, headers_1 = headers; _i < headers_1.length; _i++) {
            var header = headers_1[_i];
            var current = util.getNestedObject(config, header.primary.key) || [];
            var current_sv = header.primary.language == "key"
                ? util.translateList(current, this.props.translation, header.primary.name_type, "key->sv")
                : current;
            for (var i in allowed_values_sv) {
                var allowed_value_sv = allowed_values_sv[i];
                var checked = current_sv.indexOf(allowed_value_sv) != -1;
                if (!rows[i]) {
                    rows.push([
                        {
                            row_label: allowed_value_sv,
                        },
                    ]);
                }
                rows[i].push({
                    checked: checked,
                    header: header,
                    value_sv: allowed_value_sv,
                });
            }
        }
        return rows;
    };
    /**
     * Get rows for time type work using time type work header information
     *
     * @param config {object} Byggkollen config
     * @returns {*[]}
     */
    ConfigSimpleView.prototype.getRowsTimeTypeWork = function (config) {
        var header = this.getHeaderTimeTypeWork().slice(1);
        var allowed_values_sv = this.props.translation.time_type_names["sv"];
        var rows = this.getRows(allowed_values_sv, header, config);
        for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
            var row = rows_1[_i];
            for (var _a = 0, row_1 = row; _a < row_1.length; _a++) {
                var cell = row_1[_a];
                if (Exclude.grid_time_types[cell.value_sv] &&
                    Exclude.grid_time_types[cell.value_sv].indexOf(cell.header.label) !=
                        -1) {
                    cell.disabled = true;
                }
            }
        }
        return rows;
    };
    /**
     * Get rows for time type absence using time type work header information
     *
     * @param config {object} Byggkollen config
     * @returns {*[]}
     */
    ConfigSimpleView.prototype.getRowsTimeTypeAbsence = function (config) {
        var _this = this;
        var header = this.getHeaderTimeTypeAbsence().slice(1);
        var types = this.props.absence_time_types;
        // Check translation exists
        types = types.reduce(function (tot, val) {
            if (_this.props.translation.time_type["sv->key"][val]) {
                tot.push(val);
            }
            return tot;
        }, []);
        var rows = this.getRows(types, header, config);
        return rows;
    };
    /**
     * Get rows for time type travel (driving) using time type work header information
     *
     * @param config {object} Byggkollen config
     * @returns {*[]}
     */
    ConfigSimpleView.prototype.getRowsTravelType = function (config) {
        var header = this.getHeaderTravelType().slice(1);
        var types = this.props.translation.travel_type_names["sv"];
        var rows = this.getRows(types, header, config);
        return rows;
    };
    /**
     *  Get traktamente value one | two | none depending on config settings
     *
     * @param config {object} Byggkollen config
     * @returns {string}
     */
    ConfigSimpleView.prototype.getTraktamenteValue = function (config) {
        if (config.web.travel.traktamenteOne) {
            return "one";
        }
        else if (config.web.travel.traktamenteTwo) {
            return "two";
        }
        else {
            return "no";
        }
    };
    /**
     * Get reporting type value location | supervisor | individual
     * depending on reporting settings in config
     *
     * @param config {object} Byggkollen config
     * @returns {string}
     */
    ConfigSimpleView.prototype.getReportingTypeValue = function (config) {
        if (config.backend.locationBasedReporting) {
            return "location";
        }
        else if (config.backend.supervisorReporting) {
            return "supervisor";
        }
        else {
            return "individual";
        }
    };
    /**
     * On change callback
     *
     * @param props {object} Properties
     */
    ConfigSimpleView.prototype.onChange = function (props) {
        if (!Array.isArray(props)) {
            props = [props];
        }
        var config_edit = util.clone(this.props.config_edit);
        var _loop_1 = function (value, keys) {
            keys.forEach(function (key) {
                util.setNestedObject(config_edit, key, value);
            });
        };
        for (var _i = 0, props_1 = props; _i < props_1.length; _i++) {
            var _a = props_1[_i], value = _a.value, keys = _a.keys;
            _loop_1(value, keys);
        }
        console.log("config_edit", config_edit);
        this.props.setPageState({ config_edit: config_edit });
    };
    /**
     * On change hazard callback
     *
     * @param value {string} Hazard case ajoj | tillbud
     */
    ConfigSimpleView.prototype.onHazard = function (_a) {
        var value = _a.value;
        var config_edit = util.clone(this.props.config_edit);
        util.setNestedObject(config_edit, "mobile.projectMenu.hazard1", false);
        util.setNestedObject(config_edit, "mobile.projectMenu.hazard2", false);
        util.setNestedObject(config_edit, "web.hazardName", "");
        switch (value) {
            case "ajoj":
                util.setNestedObject(config_edit, "mobile.projectMenu.hazard1", true);
                util.setNestedObject(config_edit, "web.hazardName", "AJ! OJ!");
                break;
            case "tillbud":
                util.setNestedObject(config_edit, "mobile.projectMenu.hazard2", true);
                util.setNestedObject(config_edit, "web.hazardName", "Tillbud");
        }
        this.props.setPageState({ config_edit: config_edit });
    };
    /**
     * On change reporting type
     *
     * @param value {string} Report type selection supervisor | location
     */
    ConfigSimpleView.prototype.onReportingType = function (_a) {
        var value = _a.value;
        var config_edit = util.clone(this.props.config_edit);
        var supervisor = value == "supervisor";
        var location = value == "location";
        util.setNestedObject(config_edit, "backend.supervisorReporting", supervisor);
        util.setNestedObject(config_edit, "mobile.supervisorReporting", supervisor);
        util.setNestedObject(config_edit, "web.time.multiusertimereporting.supervisor", supervisor);
        util.setNestedObject(config_edit, "web.time.allowTimeReporting.non-supervisor", !supervisor);
        util.setNestedObject(config_edit, "web.time.allowTimeReporting.supervisor", true);
        var client_type = supervisor ? 1 : 2; // 1 (Karlsson) : 2 (Karling)
        util.setNestedObject(config_edit, "mobile.clientType", client_type);
        util.setNestedObject(config_edit, "backend.locationBasedReporting", location);
        util.setNestedObject(config_edit, "mobile.locationBasedReporting", location);
        util.setNestedObject(config_edit, "web.locationBasedReporting", location);
        this.props.setPageState({ config_edit: config_edit });
    };
    /**
     * On change allow extra time
     *
     * @param value {boolean}
     */
    ConfigSimpleView.prototype.onAllowExtraTime = function (_a) {
        var value = _a.value;
        var config_edit = util.clone(this.props.config_edit);
        util.setNestedObject(config_edit, "web.time.allowExtraTime", value);
        this.props.setPageState({ config_edit: config_edit });
    };
    /**
     * On change quick text
     *
     * @param text_list {array<string>} List with quick texts
     * @param key {string} Quick text key in config
     */
    ConfigSimpleView.prototype.onQuickText = function (_a) {
        var text_list = _a.text_list, key = _a.key;
        var config_edit = util.clone(this.props.config_edit);
        util.setNestedObject(config_edit, key, text_list);
        this.props.setPageState({ config_edit: config_edit });
    };
    /**
     * On change traktamente (allowance)
     *
     * @param value {string} Traktamente type one | two
     */
    ConfigSimpleView.prototype.onTraktamente = function (_a) {
        var value = _a.value;
        var config_edit = util.clone(this.props.config_edit);
        util.setNestedObject(config_edit, "mobile.projectMenu.traktamenteOne", false);
        util.setNestedObject(config_edit, "web.travel.traktamenteOne", false);
        util.setNestedObject(config_edit, "web.travel.allowTraktamente", false);
        util.setNestedObject(config_edit, "mobile.projectMenu.traktamenteTwo", false);
        util.setNestedObject(config_edit, "web.travel.traktamenteTwo", false);
        switch (value) {
            case "one":
                util.setNestedObject(config_edit, "mobile.projectMenu.traktamenteOne", true);
                util.setNestedObject(config_edit, "web.travel.traktamenteOne", true);
                util.setNestedObject(config_edit, "web.travel.allowTraktamente", true);
                break;
            case "two":
                util.setNestedObject(config_edit, "mobile.projectMenu.traktamenteTwo", true);
                util.setNestedObject(config_edit, "web.travel.traktamenteTwo", true);
        }
        this.props.setPageState({ config_edit: config_edit });
    };
    /**
     * On grid sort change
     *
     * @param items {array<object>} Grid items
     * @param header {array<object>} Grid view header
     */
    ConfigSimpleView.prototype.onGridSortChange = function (_a) {
        var _this = this;
        var items = _a.items, header = _a.header;
        var config_edit = util.clone(this.props.config_edit);
        var setups = util.clone(header.maps_to);
        setups.push(header.primary);
        setups.map(function (setup) {
            var list = util.clone(items);
            if (setup.language == "key") {
                list = util.translateList(list, _this.props.translation, setup.name_type, "sv->key");
            }
            // debug('setup', setup, list)
            util.setNestedObject(config_edit, setup.key, list);
        });
        this.props.setPageState({ config_edit: config_edit });
    };
    /**
     * On grid change
     *
     * @param checked {boolean} If grid option is checked
     * @param value_sv {string}  Grid options swedish translation
     * @param header {array<object>} Grid view header
     * @param config_edit_local {object} Local config edits
     * @param skip_state_update {boolean} If you want to skip state update
     */
    ConfigSimpleView.prototype.onGridChange = function (_a) {
        var _this = this;
        var checked = _a.checked, value_sv = _a.value_sv, header = _a.header, config_edit_local = _a.config_edit_local, skip_state_update = _a.skip_state_update;
        var updateList = function (list, checked, value_sv, header_setup, translation) {
            var list_sv = header_setup.language == "key"
                ? util.translateList(list, translation, header_setup.name_type, "key->sv")
                : list;
            if (checked) {
                list_sv = list_sv.reduce(function (tot, val) {
                    if (val != value_sv) {
                        tot.push(val);
                    }
                    return tot;
                }, []);
            }
            else {
                list_sv.push(value_sv);
            }
            return header_setup.language == "key"
                ? util.translateList(list_sv, translation, header_setup.name_type, "sv->key")
                : list_sv;
        };
        var config = ConfigTool.get(this.props);
        config_edit_local = config_edit_local || {};
        var setups = util.clone(header.maps_to);
        setups.push(header.primary);
        setups.map(function (setup) {
            var list = util.getNestedObject(config, setup.key) || [];
            list = updateList(list, checked, value_sv, setup, _this.props.translation);
            util.setNestedObject(config_edit_local, setup.key, list);
        });
        if (!skip_state_update) {
            var config_edit = util.clone(this.props.config_edit);
            config_edit = util.merge(config_edit, config_edit_local);
            this.props.setPageState({ config_edit: config_edit });
        }
    };
    /**
     * Sync grid with underlying map of lists. Help to mitigating conflicts
     *
     * @param key {string} Time type key time_type_travel | time_type_work | time_type_absence
     */
    ConfigSimpleView.prototype.onGridSync = function (_a) {
        var key = _a.key;
        var config = ConfigTool.get(this.props);
        var config_edit = util.clone(this.props.config_edit);
        var maps = mappingsTraveAndTimeTypes[key];
        maps = maps.slice(1); // Ignore first entry
        for (var _i = 0, maps_1 = maps; _i < maps_1.length; _i++) {
            var map = maps_1[_i];
            var setups = __spreadArray([map.primary], util.clone(map.maps_to), true);
            var _b = setups[0], key_1 = _b.key, language = _b.language;
            var list = util.getNestedObject(config, key_1) || [];
            for (var _c = 0, setups_1 = setups; _c < setups_1.length; _c++) {
                var setup = setups_1[_c];
                var key_2 = setup.key, name_type = setup.name_type;
                if (language !== setup.language) {
                    list = util.translateList(list, this.props.translation, name_type, "".concat(language, "->").concat(setup.language));
                }
                util.setNestedObject(config_edit, key_2, list);
            }
        }
        this.props.setPageState({ config_edit: config_edit });
    };
    ConfigSimpleView.prototype.onChangeAdvanceStorage = function (_a) {
        var value = _a.value;
        var config_edit = util.clone(this.props.config_edit);
        if (!config_edit.backend.advanceStorageConfig) {
            util.setNestedObject(config_edit, "backed.advanceStorageConfig", {});
        }
        this.props.setPageState({ config_edit: config_edit });
    };
    /**
     * On config edits reset
     *
     */
    ConfigSimpleView.prototype.onReset = function () {
        this.props.setPageState({
            config_edit: {},
            uid: util.getUid(),
        });
    };
    ConfigSimpleView.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var time_period = [];
        for (var i = 1; i < 29; i++) {
            time_period.push(util.toOptions(String(i)));
        }
        var config = ConfigTool.get(this.props);
        var headerTimeTypeWork = this.getHeaderTimeTypeWork();
        headerTimeTypeWork = addValues(config, headerTimeTypeWork, this.props.translation);
        var rowsTimeTypeWork = this.getRowsTimeTypeWork(config);
        var headerTimeTypeAbsence = this.getHeaderTimeTypeAbsence();
        headerTimeTypeAbsence = addValues(config, headerTimeTypeAbsence, this.props.translation);
        var rowsTimeTypeAbsence = this.getRowsTimeTypeAbsence(config);
        var headerTravelType = this.getHeaderTravelType();
        headerTravelType = addValues(config, headerTravelType, this.props.translation);
        var rowsTravelType = this.getRowsTravelType(config);
        var traktamente = [
            { value: "no", label: "No traktamente" },
            { value: "one", label: "Traktamente one" },
            { value: "two", label: "Traktamente two" },
        ];
        var reporting = [
            { value: "individual", label: "Individual" },
            { value: "location", label: "Location based" },
            { value: "supervisor", label: "Supervisor" },
        ];
        var hazard = [
            { value: "disabled", label: "Disabled" },
            { value: "ajoj", label: "Enabled AJ! OJ!" },
            { value: "tillbud", label: "Enable Tillbud" },
        ];
        var ataTypes = [
            { value: "Fastpris", label: "Fastpris" },
            { value: "Löpanderäkning", label: "Löpanderäkning" },
        ];
        var serviceTypes = [
            { value: "Löpanderäkning", label: "Löpanderäkning" },
            { value: "Fastpris", label: "Fastpris" },
        ];
        var documentStorageType = [
            { value: "onedrive", label: "OneDrive" },
            { value: "sharepoint", label: "Sharepoint" },
        ];
        var availableEmployeeTimeTypes = this.props.translation.time_type_names["sv"]
            .filter(function (e) { return !e.includes("UE"); })
            .map(util.toOptions);
        var availableSubsupplierTimeTypes = this.props.translation.time_type_names["sv"]
            .filter(function (e) { return e.includes("UE"); })
            .map(util.toOptions);
        var currentAtaTypes = config.web.ataTypes || [];
        var currentServiceTypes = config.web.serviceTypes || [];
        var error = validate(ConfigTool.get(this.props), this.props.translation.original).error;
        return (React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12 col-lg-7 mb-5" },
                    React.createElement("div", { className: "d-flex flex-row" },
                        this.props.back && React.createElement(RouterLink, __assign({}, this.props.back)),
                        this.props.continue && React.createElement(RouterLink, __assign({}, this.props.continue)),
                        this.props.to_submit && React.createElement(RouterLink, __assign({}, this.props.to_submit)),
                        React.createElement(ButtonWithTooltip, { className: "ml-auto", label: "Reset edits", onClick: this.onReset }),
                        React.createElement(ButtonWithTooltip, { className: "ml-3", label: "Sync with db", tooltip: "Synchronize with remote database. Does not reset edits.", onClick: function () { return location.reload(); } })),
                    React.createElement("div", { className: "mb-5 mt-4" },
                        React.createElement("div", { className: "d-flex flex-column" },
                            React.createElement("a", { href: "#layout" }, "Goto layout settings"),
                            React.createElement("a", { href: "#salary" }, "Goto salary system settings"))),
                    React.createElement("h2", null, "1 General settings"),
                    React.createElement("p", null, "Client Id"),
                    React.createElement("input", { type: "text", readOnly: true, value: this.state.clientId }),
                    React.createElement("section", { className: "time-period" },
                        React.createElement(InputSelectSimpleView, { options: time_period, disabled: this.props.disabled, label: "1.1 Time Period", onChange: this.onTimePeriod, value: config.mobile.startingSalaryDay, help: "Set first day for monthly reporting (1-28)" })),
                    React.createElement("section", { className: "time-types-work" },
                        React.createElement(InputCheckboxGrid, { disabled: this.props.disabled, onChange: this.onGridChange, onChangeSort: this.onGridSortChange, onSync: function () { return _this.onGridSync({ key: "time_type_work" }); }, label: "1.2 Time types work", header: util.clone(headerTimeTypeWork), rows: util.clone(rowsTimeTypeWork), help: "Set time types for work. These  are used for list of selection when reporting time. " +
                                "If you add a new time type and it is used for salary system " +
                                "then you need top add it to the " +
                                "the salary .js file in byggkollen-functions repo ( see section 3.1 Backend filename " +
                                "salary system ). If the time type does not exist here then you need to additionally " +
                                "add the time type to the firestore instance byggkollen_config.translation as well" +
                                " to android and ios project translation files. Time type entry in translation file " +
                                'should begin with "time_type_".' })),
                    React.createElement("section", { className: "time-types-absence" },
                        React.createElement(InputCheckboxGrid, { disabled: this.props.disabled, onChange: this.onGridChange, onChangeSort: this.onGridSortChange, onSync: function () { return _this.onGridSync({ key: "time_type_absence" }); }, label: "1.3 Time types absence", header: util.clone(headerTimeTypeAbsence), rows: util.clone(rowsTimeTypeAbsence), help: "Set time types for absence. !!! NOTE: The absence time type list is hardcoded in the backend. " +
                                "If you want to add to this list you need to edit file .frontend/constant/reporting.ts " +
                                "NOTE !!!. These are used for calculating " +
                                "absence time in project summary. To add a new the time type you need top add it to the " +
                                "the salary .js file in byggkollen " +
                                "functions ( see section 3.2 ). If the time type not exists there then you need to " +
                                'add it to additional places see "1.2 Time types work" section' })),
                    React.createElement("section", { className: "travel-types" },
                        React.createElement(InputCheckboxGrid, { disabled: this.props.disabled, onChange: this.onGridChange, onChangeSort: this.onGridSortChange, onSync: function () { return _this.onGridSync({ key: "travel_type" }); }, label: "1.4 Travel types", header: util.clone(headerTravelType), rows: util.clone(rowsTravelType), help: "Set travel types for work. Leave empty to exclude feature. " +
                                "To add a new travel type you need top add it to the " +
                                "the salary .js file in byggkollen-functions repo ( see section 3.1 Backend filename " +
                                "salary system ). If the travel type does not exist here then you need to additionally " +
                                "add the travel type to the firestore instance byggkollen_config.translation as well" +
                                " to android and ios project translation files. Travel type entry in translation file " +
                                'should begin with "travel_type_".' })),
                    React.createElement("section", { className: "traktamente" },
                        React.createElement(InputSelectSimpleView, { options: traktamente, disabled: this.props.disabled, label: "1.5 Traktamente", onChange: this.onTraktamente, value: this.getTraktamenteValue(config), help: "Set traktamente. Be aware that web.travel.allowTraktamente " +
                                "is an old flag that should always be equal to " +
                                "web.travel.traktamenteOne. This flag is meaningless if " +
                                "traktamente 2 is in use." })),
                    React.createElement("section", { className: "weekly-reminder" },
                        React.createElement("label", null, "1.6 Weekly reminder"),
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.6.1 Weekly reminder monday", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["backend.cron.pushNotificationMonday"],
                                });
                            }, value: config.backend.cron.pushNotificationMonday, help: "Get weekly reminder on Monday to submit reports. Sent only to users missing hours " +
                                "for previous week" }),
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.6.2 Weekly reminder friday", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["backend.cron.pushNotificationFriday"],
                                });
                            }, value: config.backend.cron.pushNotificationFriday, help: "Get weekly reminder on Friday to submit reports. Sent to all users" })),
                    React.createElement("section", { className: "quick-text-section" },
                        React.createElement(InputTextList, { disabled: this.props.disabled, label: "1.7 Quick text project diary", text_list: config.mobile.quickText, onChange: function (_a) {
                                var text_list = _a.text_list;
                                return _this.onQuickText({
                                    text_list: text_list,
                                    key: "mobile.quickText",
                                });
                            } })),
                    React.createElement("section", { className: "quick-text-section" },
                        React.createElement(InputTextList, { disabled: this.props.disabled, label: "1.8 Quick text service order diary", text_list: config.mobile.serviceorderQuickText, onChange: function (_a) {
                                var text_list = _a.text_list;
                                return _this.onQuickText({
                                    text_list: text_list,
                                    key: "mobile.serviceorderQuickText",
                                });
                            } })),
                    React.createElement("section", { className: "allow-reporting-extra-time" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.8 Allow reporting extra time", onChange: this.onAllowExtraTime, value: config.web.time.allowExtraTime, help: "Sets if company is allowed to report extra time" })),
                    React.createElement("section", { className: "supervisor-reporting" },
                        React.createElement(InputSelectSimpleView, { options: reporting, disabled: this.props.disabled, label: "1.9 Supervisor reporting", onChange: this.onReportingType, value: this.getReportingTypeValue(config), help: React.createElement("div", null,
                                React.createElement("div", null, "Individual reporting: Is when all users generate their own reporting. Workers report their own worked hours, driving, and other project information."),
                                React.createElement("div", { className: "mt-1" }, this.getHelp("backend.locationBasedReporting")),
                                React.createElement("div", { className: "mt-1" }, this.getHelp("backend.supervisorReporting"))) })),
                    config.backend.supervisorReporting && (React.createElement("section", { className: "overtime-rules" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.9.1 Separate work task", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "backend.separateWorkTask",
                                        "web.separateWorkTask",
                                        "mobile.separateWorkTask",
                                    ],
                                });
                            }, value: config.backend.separateWorkTask, help: this.getHelp("backend.separateWorkTask") }),
                        config.backend.separateWorkTask && (React.createElement("div", { className: "flex-column" },
                            React.createElement(InputSupervisorSalaryTimeType, { label: "Employee", availableTimeTypes: availableEmployeeTimeTypes, translation: this.props.translation, timeTypes: config.backend.employeeSalaryTimeTypes, onChange: function (value) {
                                    return _this.onChange({
                                        value: value,
                                        keys: [
                                            "backend.employeeSalaryTimeTypes",
                                            "web.employeeSalaryTimeTypes",
                                            "mobile.employeeSalaryTimeTypes",
                                        ],
                                    });
                                } }),
                            React.createElement(InputSupervisorSalaryTimeType, { label: "Subsupplier", translation: this.props.translation, availableTimeTypes: availableSubsupplierTimeTypes, timeTypes: config.backend.subSupplierSalaryTimeTypes, onChange: function (value) {
                                    return _this.onChange({
                                        value: value,
                                        keys: [
                                            "backend.subSupplierSalaryTimeTypes",
                                            "web.subSupplierSalaryTimeTypes",
                                            "mobile.subSupplierSalaryTimeTypes",
                                        ],
                                    });
                                } }))),
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.9.2 Material/equipment report on fixed price projects", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "backend.enableResourceReporting",
                                        "web.enableResourceReporting",
                                        "mobile.enableResourceReporting",
                                    ],
                                });
                            }, value: config.backend.enableResourceReporting, help: this.getHelp("backend.enableResourceReporting") }))),
                    config.backend.locationBasedReporting && (React.createElement("section", { className: "overtime-rules" },
                        React.createElement(InputOvertimeRules, { disabled: this.props.disabled, label: "1.9.1 Overtime rules", overtimeRules: config.backend.overtimeRules, onChange: function (value) {
                                return _this.onChange({
                                    value: value,
                                    keys: ["backend.overtimeRules", "mobile.overtimeRules"],
                                });
                            } }))),
                    React.createElement("section", { className: "supervisor-reporting" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.10 Enable reporting project running material equipment on mobile ", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "mobile.enableReportingProjectRunningMaterialEquipment",
                                    ],
                                });
                            }, value: config.mobile.enableReportingProjectRunningMaterialEquipment, help: this.getHelp("mobile.enableReportingProjectRunningMaterialEquipment") })),
                    React.createElement("section", { className: "driving-expense" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.11 Driving expense", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["mobile.projectMenu.drivingExpense"],
                                });
                            }, value: config.mobile.projectMenu.drivingExpense, help: "Sets if company is reporting driving expenses from own menu item (not reporting " +
                                "flow). Recommended that this is true if supervisor reporting is chosen" })),
                    React.createElement("section", { className: "menu-documents" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.12 Show documents on project menu", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "web.active_modules.project.documents",
                                        "mobile.projectMenu.documents",
                                    ],
                                });
                            }, value: config.web.active_modules.project.documents, help: "Should project menu show documents" })),
                    React.createElement("section", { className: "menu-qa" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.13 Show QA on project menu", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "web.active_modules.project.QA",
                                        "mobile.projectMenu.questionsAnswer",
                                    ],
                                });
                            }, value: config.web.active_modules.project.QA, help: "Should project menu show questions and answers" })),
                    React.createElement("section", { className: "subcompanies" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.14 Subcompanies", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.active_modules.settings.subcompanies"],
                                });
                            }, value: config.web.active_modules.settings.subcompanies, help: "Allow subcompanies. Two additional steps are required to complete manual setup:" +
                                "First add subcompanies in BK instance under Settings. " +
                                "Second select correct subcompany for each employee" })),
                    React.createElement("section", { className: "hazard" },
                        React.createElement(InputSelectSimpleView, { options: hazard, disabled: this.props.disabled, label: "1.15 Hazard", onChange: this.onHazard, value: this.getHazardValue(config), help: "Set hazard" })),
                    React.createElement("section", { className: "ml-project-overrun" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.16 Project overrun tool", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.active_modules.project.ml_proj_overrun"],
                                });
                            }, value: config.web.active_modules.project.ml_proj_overrun, help: "Show project overrun tool" })),
                    React.createElement("section", { className: "safety-round" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.17 Safety round", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "web.active_modules.project.safety_round",
                                        "mobile.projectMenu.safety_round",
                                    ],
                                });
                            }, value: config.web.active_modules.project.safety_round, help: "Show safety round" })),
                    React.createElement("section", { className: "ata-for-all" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.18 Äta for all", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.time.ataForAll", "mobile.ataForAll"],
                                });
                            }, value: config.web.time.ataForAll, help: "Allow all to see and report äta" })),
                    React.createElement("section", { className: "language" },
                        React.createElement(InputSelectSimpleView, { options: util.sort(this.props.locales_available_for_web.map(function (m) {
                                return { value: m, label: m };
                            })), disabled: this.props.disabled, label: "1.19 Default language", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["backend.language"],
                                });
                            }, value: config.backend.language, help: this.getHelp("backend.language") })),
                    React.createElement("section", { className: "absence-reporting" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.20 Absence reporting", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.active_modules.settings.absenceReporting"],
                                });
                            }, value: config.web.active_modules.settings.absenceReporting, help: this.getHelp("web.active_modules.settings.absenceReporting") })),
                    React.createElement("section", { className: "notify-supervisor-new-ata" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.22 Notify supervisor when new Äta is created", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["backend.notifySupervisorNewATA"],
                                });
                            }, value: config.backend.notifySupervisorNewATA, help: this.getHelp("backend.notifySupervisorNewATA") })),
                    React.createElement("section", { className: "ata-types" },
                        React.createElement(InputSelectReactSimpleView, { options: ataTypes, disabled: this.props.disabled, multi: true, label: "1.23 Äta types", selected: currentAtaTypes.map(function (s) { return util.toOptions(s); }), onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.ataTypes", "mobile.ataTypes"],
                                });
                            }, help: this.getHelp("web.ataTypes") })),
                    React.createElement("section", { className: "service-price-types" },
                        React.createElement(InputSelectReactSimpleView, { options: serviceTypes, disabled: this.props.disabled, multi: true, label: "1.24 Service price types", selected: currentServiceTypes.map(function (s) { return util.toOptions(s); }), onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.serviceTypes", "mobile.serviceTypes"],
                                });
                            }, help: this.getHelp("web.serviceTypes") })),
                    React.createElement("section", { className: "accurasee-integration" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.25 Accurasee ready", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "backend.accuraseeReady",
                                        "web.accuraseeReady",
                                        "mobile.accuraseeReady",
                                    ],
                                });
                            }, value: config.backend.accuraseeReady, help: "Accurasee ready" })),
                    React.createElement("section", { className: "accurasee-integration" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.26 Has Accurasee integration", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "backend.accuraseeIntegration",
                                        "web.accuraseeIntegration",
                                        "mobile.accuraseeIntegration",
                                    ],
                                });
                            }, value: config.backend.accuraseeIntegration, help: "Integrate with Accurasee" })),
                    config.backend.accuraseeIntegration && (React.createElement("section", { className: "accurasee-integration-key" },
                        React.createElement(InputTextSimpleView, { disabled: this.props.disabled, label: "1.26.1 Accurasee integration API key", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["backend.accuraseeIntegrationAPIKey"],
                                });
                            }, value: config.backend.accuraseeIntegrationAPIKey, help: "The API Key from Accurasee", placeholder: "API key ..." }),
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.26.2 Disable sync daily", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["backend.accuraseeDisableSyncDaily"],
                                });
                            }, value: config.backend.accuraseeDisableSyncDaily, help: "Disable sync daily" }))),
                    React.createElement("section", { className: "advance-document-handling" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.27 Enable advance document handling", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "backend.advanceDocumentHandling",
                                        "web.advanceDocumentHandling",
                                        "mobile.advanceDocumentHandling",
                                    ],
                                });
                            }, value: config.backend.advanceDocumentHandling, help: "Enable advance document handling" })),
                    config.backend.advanceDocumentHandling && (React.createElement("div", null,
                        React.createElement("section", { className: "storage-type-key" },
                            React.createElement(InputSelectSimpleView, { options: documentStorageType, disabled: this.props.disabled, label: "1.27.1 ", onChange: function (_a) {
                                    var value = _a.value;
                                    _this.onChange({
                                        value: value,
                                        keys: [
                                            "backend.advanceDocumentConfig.storageType",
                                            "web.advanceDocumentConfig.storageType",
                                        ],
                                    });
                                }, value: ((_a = config.backend.advanceDocumentConfig) === null || _a === void 0 ? void 0 : _a.storageType) ||
                                    "onedrive", help: this.getHelp("backend.advanceDocumentConfig.storageType") })),
                        React.createElement("section", { className: "storage-url-key" },
                            React.createElement(InputTextSimpleView, { disabled: this.props.disabled, label: "1.27.2 One drive / Sharepoint url", onChange: function (_a) {
                                    var value = _a.value;
                                    return _this.onChange({
                                        value: value,
                                        keys: [
                                            "backend.advanceDocumentConfig.storageUrl",
                                            "web.advanceDocumentConfig.storageUrl",
                                        ],
                                    });
                                }, value: (_b = config.backend.advanceDocumentConfig) === null || _b === void 0 ? void 0 : _b.storageUrl, help: this.getHelp("backend.advanceDocumentConfig.storageUrl"), placeholder: "" })),
                        React.createElement("section", { className: "storage-save-location-key" },
                            React.createElement(InputTextSimpleView, { disabled: this.props.disabled, label: "1.27.3 Path to saved location", onChange: function (_a) {
                                    var value = _a.value;
                                    return _this.onChange({
                                        value: value !== null && value !== void 0 ? value : "",
                                        keys: [
                                            "backend.advanceDocumentConfig.storageSharedSaveLocationUrl",
                                            "web.advanceDocumentConfig.storageSharedSaveLocationUrl",
                                        ],
                                    });
                                }, value: (_c = config.backend.advanceDocumentConfig) === null || _c === void 0 ? void 0 : _c.storageSharedSaveLocationUrl, help: this.getHelp("backend.advanceDocumentConfig.storageSharedSaveLocationUrl"), placeholder: "" }),
                            React.createElement(InputTextSimpleView, { disabled: this.props.disabled, onChange: function (_a) {
                                    var value = _a.value;
                                    return _this.onChange({
                                        value: value !== null && value !== void 0 ? value : "",
                                        keys: [
                                            "backend.advanceDocumentConfig.storageSaveLocation",
                                            "web.advanceDocumentConfig.storageSaveLocation",
                                        ],
                                    });
                                }, value: (_d = config.backend.advanceDocumentConfig) === null || _d === void 0 ? void 0 : _d.storageSaveLocation, help: this.getHelp("backend.advanceDocumentConfig.storageSaveLocation"), placeholder: "" })),
                        React.createElement("section", { className: "storage-template-location-key" },
                            React.createElement(InputTextSimpleView, { disabled: this.props.disabled, label: "1.27.4 Path to template folder", onChange: function (_a) {
                                    var value = _a.value;
                                    return _this.onChange({
                                        value: value !== null && value !== void 0 ? value : "",
                                        keys: [
                                            "backend.advanceDocumentConfig.storageSharedTemplateUrl",
                                            "web.advanceDocumentConfig.storageSharedTemplateUrl",
                                        ],
                                    });
                                }, value: (_e = config.backend.advanceDocumentConfig) === null || _e === void 0 ? void 0 : _e.storageSharedTemplateUrl, help: this.getHelp("backend.advanceDocumentConfig.storageSharedTemplateUrl"), placeholder: "" }),
                            React.createElement(InputTextSimpleView, { disabled: this.props.disabled, onChange: function (_a) {
                                    var value = _a.value;
                                    return _this.onChange({
                                        value: value !== null && value !== void 0 ? value : "",
                                        keys: [
                                            "backend.advanceDocumentConfig.storageTemplateLocation",
                                            "web.advanceDocumentConfig.storageTemplateLocation",
                                        ],
                                    });
                                }, value: (_f = config.backend.advanceDocumentConfig) === null || _f === void 0 ? void 0 : _f.storageTemplateLocation, help: this.getHelp("backend.advanceDocumentConfig.storageTemplateLocation"), placeholder: "" })))),
                    React.createElement("section", { className: "attest-menu" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.28.1 Show attest per project", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.active_modules.attestMenu.project"],
                                });
                            }, value: (_g = config.web.active_modules.attestMenu) === null || _g === void 0 ? void 0 : _g.project, help: "Should attest per project" }),
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.28.2 Show attest per service", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.active_modules.attestMenu.service"],
                                });
                            }, value: (_h = config.web.active_modules.attestMenu) === null || _h === void 0 ? void 0 : _h.service, help: "Should attest per service" }),
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.28.3 Show environment summary", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.active_modules.environmentSummary"],
                                });
                            }, value: config.web.active_modules.environmentSummary, help: "Show environment summary" })),
                    React.createElement("section", { className: "duplicate-project-id" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.29 Allow duplicated project id", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.allowDuplicatedProjectId"],
                                });
                            }, value: config.web.allowDuplicatedProjectId, help: "Allow duplicated project id" })),
                    React.createElement("section", { className: "project-evaluation" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.30 Project evaluation", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.projectEvaluation"],
                                });
                            }, value: config.web.projectEvaluation, help: "Enable project evaluation" })),
                    React.createElement("section", { className: "scaffold-rental" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.31 Scaffold rental", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.scaffoldRental", "backend.scaffoldRental"],
                                });
                            }, value: config.web.scaffoldRental, help: "Enable scaffold rental" })),
                    React.createElement("section", { className: "task-list" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.32 Task list", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.taskList", "mobile.taskList"],
                                });
                            }, value: config.web.taskList, help: "Enable task list feature" })),
                    React.createElement("section", { className: "sensitive-info" },
                        React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "1.33 Hide sensitive info", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.hideSensitiveInfo", "mobile.hideSensitiveInfo"],
                                });
                            }, value: config.web.hideSensitiveInfo, help: "Hide sensitive info for users with no hours registered on a project" })),
                    React.createElement("h2", { id: "layout" }, "2 Layout"),
                    React.createElement("section", { className: "company-name" },
                        React.createElement(InputTextSimpleView, { disabled: this.props.disabled, label: "2.1 Company name", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.companyName", "backend.companyName"],
                                });
                            }, value: config.backend.companyName, help: "The company name must match the default company name for salary export" +
                                " exactly. This can be configured in salary export, in section 3", placeholder: "Add full company name ..." })),
                    React.createElement("section", { className: "company-name-short" },
                        React.createElement(InputTextSimpleView, { disabled: this.props.disabled, label: "2.2 Company name short", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["backend.company"],
                                });
                            }, value: config.backend.company, help: "Set short company name", placeholder: "Add short company name ..." })),
                    React.createElement("section", { className: "knowledge-url" },
                        React.createElement(InputTextSimpleView, { disabled: this.props.disabled, label: "2.3 Knowledge url", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["mobile.knowledgeBaseUrl"],
                                });
                            }, value: config.mobile.knowledgeBaseUrl, help: "Link to knowledge database. Need to be set up manually", placeholder: "Add knowledge database url ..." })),
                    React.createElement("section", { className: "primary-color" },
                        React.createElement(InputColorSimpleView, { disabled: this.props.disabled, label: "2.4 Primary color", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "web.theme.primaryColor",
                                        "mobile.primaryColor",
                                        "web.theme.datepickerSndColor",
                                        "web.theme.navTextColor",
                                    ],
                                });
                            }, value: config.mobile.primaryColor, help: "Set primary color for mobile and web" })),
                    React.createElement("section", { className: "accent-color-mobile" },
                        React.createElement(InputColorSimpleView, { disabled: this.props.disabled, label: "2.5 Accent color mobile", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["mobile.accentColor"],
                                });
                            }, value: config.mobile.accentColor, help: "Set accent color for mobile" })),
                    React.createElement("section", { className: "secondary-color-web" },
                        React.createElement(InputColorSimpleView, { disabled: this.props.disabled, label: "2.6 Secondary color web", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: ["web.theme.secondaryColor"],
                                });
                            }, value: config.web.theme.secondaryColor, help: "Set secondary color for web" })),
                    React.createElement("section", { className: "secondary-color-web" },
                        React.createElement(InputColorSimpleView, { disabled: this.props.disabled, label: "2.7 Secondary text color web", onChange: function (_a) {
                                var value = _a.value;
                                return _this.onChange({
                                    value: value,
                                    keys: [
                                        "web.theme.datepickerColor",
                                        "web.theme.settingsReportColor",
                                    ],
                                });
                            }, value: config.web.theme.datepickerColor, help: "Set text secondary color for web" })),
                    React.createElement("h2", { id: "salary" }, "3 Salary system"),
                    React.createElement("section", { className: "salary-system-type" },
                        React.createElement(InputSelectSimpleView, { options: [
                                { value: "none", label: "No salary system" },
                                { value: "fortnox", label: "fortnox" },
                                { value: "hogia", label: "hogia" },
                                { value: "kontek", label: "kontek" },
                                { value: "visma", label: "visma" },
                            ], disabled: this.props.disabled, label: "3.1 Salary system type", onChange: function (_a) {
                                var value = _a.value;
                                _this.onChange([
                                    {
                                        value: value,
                                        keys: ["backend.salarySystem.type"],
                                    },
                                    {
                                        value: value == "none"
                                            ? null
                                            : value == "visma"
                                                ? ".tlu"
                                                : ".xml",
                                        keys: ["backend.salarySystem.extension"],
                                    },
                                ]);
                            }, value: config.backend.salarySystem.type, help: this.getHelp("backend.salarySystem.type") })),
                    !["none", ""].includes(config.backend.salarySystem.type) && (React.createElement(React.Fragment, null,
                        React.createElement("section", { className: "salary-system-legacy" },
                            React.createElement(InputCheckboxSimpleView, { disabled: this.props.disabled, label: "3.2 Enable legacy", onChange: function (_a) {
                                    var value = _a.value;
                                    return _this.onChange({
                                        value: value,
                                        keys: ["backend.salarySystem.legacy"],
                                    });
                                }, value: config.backend.salarySystem.legacy, help: this.getHelp("backend.salarySystem.legacy") })),
                        !config.backend.salarySystem.legacy && (React.createElement("section", null,
                            React.createElement(InputSalary, { default_company: config.backend.salarySystem.default_company, default_company_help: this.getHelp("backend.salarySystem.default_company"), companies: config.backend.salarySystem.companies || [], data_type_options: getDataTypes(config, mapping, this.props.translation, this.props.allowance_mapping), docs: this.state.docs, issues: error, label: "3.4 Configure salary systems", help: this.getHelp("backend.salarySystem.companies"), onChange: function (_a) {
                                    var value = _a.value;
                                    debug("Input salary onChange", value);
                                    _this.onChange({
                                        value: value,
                                        keys: ["backend.salarySystem.companies"],
                                    });
                                }, onChangeDelete: function (_a) {
                                    var value = _a.value;
                                    _this.onChange([
                                        {
                                            value: value.companies,
                                            keys: ["backend.salarySystem.companies"],
                                        },
                                        {
                                            value: value.default_company,
                                            keys: ["backend.salarySystem.default_company"],
                                        },
                                    ]);
                                }, onChangeDefaultCompany: function (_a) {
                                    var value = _a.value;
                                    _this.onChange({
                                        value: value,
                                        keys: ["backend.salarySystem.default_company"],
                                    });
                                }, subcompanies: this.props.subcompanies }))))),
                    React.createElement("div", { className: "d-flex flex-row" },
                        this.props.back && React.createElement(RouterLink, __assign({}, this.props.back)),
                        this.props.continue && React.createElement(RouterLink, __assign({}, this.props.continue)),
                        this.props.to_submit && React.createElement(RouterLink, __assign({}, this.props.to_submit)))),
                React.createElement("div", { className: ".d-none .d-lg-block col-lg-5" },
                    React.createElement("h3", null, "Issues"),
                    error.map(function (issue, index) { return (React.createElement(Issue, __assign({ key: index }, { issue: issue, index: index }))); })))));
    };
    ConfigSimpleView.propTypes = {
        absence_time_types: PropTypes.array.isRequired,
        back: PropTypes.object,
        config: PropTypes.object.isRequired,
        config_edit: PropTypes.object.isRequired,
        continue: PropTypes.object,
        disabled: PropTypes.bool,
        setPageState: PropTypes.func.isRequired,
        subcompanies: PropTypes.array.isRequired,
        to_submit: PropTypes.object,
        translation: PropTypes.object.isRequired,
        locales_available_for_web: PropTypes.array.isRequired,
        type: PropTypes.string.isRequired,
    };
    return ConfigSimpleView;
}(Component));
export default ConfigSimpleView;
